<template>
  <b-modal
    :id="`modal-importar-${modulo}`"
    v-model="modalShow"
    centered
    size="lg"
    :title="`Importar ${modulo}`"
    width="900px"
    hide-footer
  >
    <!-- warning -->
    <b-alert
      variant="primary"
      show
    >
      <h4 class="alert-heading">
        Nota
      </h4>
      <div class="alert-body">
        <span>
          Para importar Productos y Precios Cantidad, ambos archivos no deben tener errores!.<br>
          Recuerde cargar siempre primero el archivo con Productos y posteriormente el con Precios Cantidad
        </span>
      </div>
    </b-alert>
    <b-row>
      <b-col>
        <b-button
          class="mt-1"
          variant="outline-success"
          block
          size="sm"
          :title="`Formato para importar ${modulo}`"
          :disabled="cargando"
          @click.prevent="!cargando
            ? downloadFormat()
            : ''"
        >
          Descargar Formato Productos
        </b-button>
      </b-col>
      <b-col>
        <b-button
          class="mt-1"
          variant="outline-success"
          block
          size="sm"
          :title="`Formato para importar Precios por Cantidad de ${modulo}`"
          :disabled="cargando"
          @click.prevent="!cargando
            ? downloadFormatPreciosCantidad()
            : ''"
        >
          Descargar Formato Productos Precios Cantidad
        </b-button>
      </b-col>
    </b-row>

    <spinner
      v-if="cargando"
      class="mt-3"
    />
    <div
      v-else
      class=" flex-wrap"
    >
      <b-form-file
        id="input-file-import"
        ref="importFile"
        v-model="fileProductos"
        name="file_import"
        class="mt-1"
        type="file"
        accept=".xls, .xlsx, .csv"
        :placeholder="!fileNameProductos
          ? `Selecciona el archivo para importar ${modulo}...`
          : fileNameProductos"
        drop-placeholder="Arrastra tu archivo aquí..."
        style="cursor: pointer;"
        @change="onFileChangeProductos"
      />
      <!-- ERRORES PRODUCTOS -->
      <div
        v-if="dataExcel.length"
        class="mt-1 text-success"
      >
        <feather-icon
          icon="CheckIcon"
        />
        {{ countCargados }} {{ modulo }} cargados correctamente
      </div>

      <div
        v-if="headerErrorMsg.length"
        class="mt-3 mb-2  text-danger"
      >
        - ERROR DE ENCABEZADO! <br><br>
        <div
          v-for="(error, i) in headerErrorMsg"
          :key="i"
        >
          <feather-icon
            icon="XIcon"
          /> {{ error }}
        </div>
      </div>

      <template
        v-if="dataError.length"
      >
        <div
          class="text-danger"
        >
          <feather-icon
            icon="XIcon"
          />
          {{ countErrors }} {{ modulo }} no cargados
        </div>
        <errorLog
          v-if="dataError.length > 0"
          :modulo="modulo"
          @exportarErrores="exportarErrores"
          @exportarLog="exportarLog"
        />
      </template>

      <b-form-file
        v-if="disabledFilePreciosCantidad"
        id="input-file-import-precios-cantidad"
        ref="importFilePreciosCantidad"
        v-model="filePreciosCantidad"
        name="file_import_precios_cantidad"
        class="mt-1"
        type="file"
        accept=".xls, .xlsx, .csv"
        :placeholder="!fileNamePreciosCantidad
          ? `Selecciona el archivo para importar Productos
            Precios Cantidad...`
          : fileNamePreciosCantidad"
        drop-placeholder="Arrastra tu archivo aquí..."
        style="cursor: pointer;"
        @change="onFileChangePreciosCantidad"
      />
      <!-- ERRORES PRECIOS CANTIDAD -->
      <div
        v-if="dataExcelPreciosCantidad.length"
        class="mt-1 text-success"
      >
        <feather-icon
          icon="CheckIcon"
        />
        {{ countCargadosPreciosCantidad }} {{ moduloPreciosCantidad }} cargados correctamente
      </div>

      <div
        v-if="headerErrorMsgPreciosCantidad.length"
        class="mt-3 mb-2  text-danger"
      >
        - ERROR DE ENCABEZADO! <br><br>
        <div
          v-for="(errorPreciosCant, e) in headerErrorMsgPreciosCantidad"
          :key="e"
        >
          <feather-icon
            icon="XIcon"
          /> {{ errorPreciosCant }}
        </div>
      </div>

      <template
        v-if="dataErrorPreciosCantidad.length"
      >
        <div
          class="text-danger"
        >
          <feather-icon
            icon="XIcon"
          />
          {{ countErrorsPreciosCantidad }} {{ moduloPreciosCantidad }} no cargados
        </div>
        <errorLog
          v-if="dataErrorPreciosCantidad.length > 1"
          :modulo="moduloPreciosCantidad"
          @exportarErrores="exportarErroresPreciosCantidad"
          @exportarLog="exportarLogPreciosCantidad"
        />
      </template>
      <div
        class="d-flex align-items-center justify-content-end"
      >
        <b-button
          class="mt-2 mb-1 mr-1 btn-sm-block"
          variant="outline-secondary"
          :disabled="cargando"
          @click.prevent="closeModal()"
        >
          Cerrar
        </b-button>
        <b-button
          class="mt-2 mb-1 btn-sm-block"
          variant="primary"
          :disabled="!disabledImportBtn"
          @click.prevent="disabledImportBtn
            ? processImport()
            : ''"
        >
          Importar {{ modulo }}
          {{
            dataError.length
              ? ' Cargados Correctamente'
              : ''
          }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal, BFormFile, BButton, BRow, BCol, BAlert,
} from 'bootstrap-vue'
import readXlsxFile from 'read-excel-file'
import { validationMixin } from 'vuelidate'
import {
  required, maxLength,
} from 'vuelidate/lib/validators'
import axios from 'axios'
import { mapActions, mapGetters, mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import XLSX from 'xlsx'

const spinner = () => import('@/layouts/components/Recycled/Form/spinner.vue')
const errorLog = () => import('@/layouts/components/Productos/components/errorLogs.vue')

export default {
  components: {
    BModal,
    BFormFile,
    BButton,
    BRow,
    BCol,
    BAlert,

    spinner,
    errorLog,
  },
  mixins: [validationMixin],
  props: {
    modulo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      moduloPreciosCantidad: 'PreciosCantidad',
      modalShow: false,
      // disabledImportBtn: false,
      cargando: false,
      dataExcel: [],
      dataError: [],
      dataErrorMsg: [],
      headerValido: false,
      headerErrorMsg: [],

      dataExcelPreciosCantidad: [],
      dataErrorPreciosCantidad: [],
      dataErrorMsgPreciosCantidad: [],
      headerValidoPreciosCantidad: false,
      headerErrorMsgPreciosCantidad: [],

      dataProductos: [],
      dataPreciosCantidad: [],
      fileProductos: null,
      filePreciosCantidad: null,
      fileNameProductos: '',
      fileNamePreciosCantidad: '',

      descargados: [],
      descargadosPreciosCantidad: [],

      productoCodigos: [],
      productosSinError: [],

      productos: {
        nombre: '',
        codigo: '',
        unidad: '',
        categoria: '',
        ubicacion: '',
        stock: '',
        tramoDesdeRojo: '',
        tramoDesdeAmarillo: '',
        tramoDesdeVerde: '',
        descripcion: '',
      },
      nombreColumna: [
        'Nombre*',
        'Código*',
        'Unidad',
        'Categoría',
        'Ubicación',
        'Stock',
        'Alarma Roja*',
        'Alarma Amarilla*',
        'Alarma Verde*',
        'Descripción',
      ],
      columna: {
        0: 'A',
        1: 'B',
        2: 'C',
        3: 'D',
        4: 'E',
        5: 'F',
        6: 'G',
        7: 'H',
        8: 'I',
        9: 'J',
      },
      preciosCantidad: {
        codigoProducto: '',
        cantidad: '',
        precio: '',
      },
      nombreColumnaPreciosCantidad: [
        'Código Producto',
        'Cantidad Desde',
        'Precio',
      ],
      columnaPreciosCantidad: {
        0: 'A',
        1: 'B',
        2: 'C',
      },
    }
  },
  computed: {
    ...mapGetters({ getProductos: 'productos/getProductos' }),
    ...mapState('productos', {
      unidades: state => state.unidades,
    }),
    countCargados() {
      const result = this.dataExcel.length > 0
        ? Number(this.dataExcel.length - 1)
        : false
      return result
    },
    countErrors() {
      return this.dataError.length
    },
    countCargadosPreciosCantidad() {
      const result = this.dataExcelPreciosCantidad.length > 0
        ? Number(this.dataExcelPreciosCantidad.length - 1)
        : false
      return result
    },
    countErrorsPreciosCantidad() {
      return this.dataErrorPreciosCantidad.length
    },
    disabledImportBtn() {
      let disabled
      // TODO: DISABLE BTN
      //! Mientras existan errores, que no se pueda ingresar nada
      //! El archivo que debe obtener el usuario es un excel con toda la información
      //! El error log solo debe marcar las celdas con error
      if (this.dataExcel.length > 1 && this.filePreciosCantidad !== null) {
        if (this.dataError.length > 0 || this.dataErrorPreciosCantidad.length > 0) {
          disabled = false
        } else {
          disabled = true
        }
      }
      return disabled
    },
    disabledFilePreciosCantidad() {
      return this.fileNameProductos
    },
  },
  watch: {
    modalShow() {
      if (!this.modalShow) {
        this.resetProductos()
        this.resetPreciosCantidad()
      }
    },
  },
  validations() {
    return {
      productos: {
        nombre: {
          required,
          maxLength: maxLength(80),
        },
        codigo: {
          required,
          maxLength: maxLength(30),
          duplicadoExcel: this.filterCodigoExcel,
          duplicadoSys: this.filterCodigoSys,
        },
        unidad: {
          existeUnidad: this.filterUnidades,
        },
        tramoDesdeRojo: {
          required,
        },
        tramoDesdeAmarillo: {
          required,
          mayorRojo: this.validaAmarillo,
        },
        tramoDesdeVerde: {
          required,
          mayorAmarillo: this.validaVerde,
        },
      },
      preciosCantidad: {
        codigoProducto: {
          required,
          existeProducto: this.findPreciosProductos,
        },
        cantidad: {
          required,
        },
        precio: {
          required,
        },
      },
    }
  },
  methods: {
    ...mapActions({
      fetchProductos: 'productos/fetchProductos',
      importProductos: 'productos/importProductos',
    }),
    onFileChangeProductos(event) {
      this.resetProductos()
      this.resetPreciosCantidad()
      this.cargando = true
      const xlsxfile = event.target.files ? event.target.files[0] : null
      this.fileNameProductos = xlsxfile.name
      readXlsxFile(xlsxfile).then(data => {
        this.dataProductos.push(data)
        this.validarImportProducto(data)
        this.cargando = false
      })
    },
    onFileChangePreciosCantidad(event) {
      this.resetPreciosCantidad()
      this.cargando = true
      const xlsxfile = event.target.files ? event.target.files[0] : null
      this.fileNamePreciosCantidad = xlsxfile.name
      readXlsxFile(xlsxfile).then(data => {
        this.dataPreciosCantidad.push(data)
        this.validarImportPreciosCantidad(data)
        this.cargando = false
      })
    },
    validarImportProducto(productos) {
      if (productos.length > 0) {
        const responseProductos = this.validaHeaderExcel(
          productos[0],
          this.nombreColumna,
          'PRODUCTOS',
        )

        const response = []
        if (responseProductos) {
          responseProductos.forEach(res => {
            response.push(res)
          })
        }
        if (response.length !== 0) {
          this.headerValido = false
          this.headerErrorMsg = response
        } else {
          this.headerValido = true
          this.dataExcel.push(productos[0])
        }
      }

      if (this.headerValido) {
        //* fetchProductos: Obtenemos this.getProductos,
        //* para consultar los códigos actualizados ingresados en productos.
        //* Así validamos que no se dupliquen dentro de sistema
        this.fetchProductos().then(() => {
          this.setCodigosExcel(productos)
          productos.forEach((filaData, keyDataFila) => {
            if (keyDataFila !== 0) {
              this.validaBodyExcelProductos(filaData, keyDataFila)
              if (!this.$v.productos.$invalid) {
                this.dataExcel.push(filaData)
                this.productosSinError.push({
                  codigo: filaData[1],
                })
              } else {
                this.dataError.push(filaData)
              }
            }
          })
        })
      }
      this.productoCodigos = []
    },
    validarImportPreciosCantidad(precioCantidad) {
      if (precioCantidad.length > 0) {
        const responsePrecios = this.validaHeaderExcel(
          precioCantidad[0],
          this.nombreColumnaPreciosCantidad,
          'PRECIOS POR CANTIDAD',
        )

        const response = []
        if (responsePrecios) {
          responsePrecios.forEach(res => {
            response.push(res)
          })
        }
        if (response.length !== 0) {
          this.headerValidoPreciosCantidad = false
          this.headerErrorMsgPreciosCantidad = response
        } else {
          this.headerValidoPreciosCantidad = true
          this.dataExcelPreciosCantidad.push(precioCantidad[0])
        }
      }
      if (this.headerValidoPreciosCantidad) {
        precioCantidad.forEach((filaData, keyDataFila) => {
          if (keyDataFila !== 0) {
            this.validaBodyExcelPreciosCantidad(filaData, keyDataFila)
            if (!this.$v.preciosCantidad.$invalid) {
              this.dataExcelPreciosCantidad.push(filaData)
            } else {
              this.dataErrorPreciosCantidad.push(filaData)
            }
          }
        })
      }
    },
    findPreciosProductos() {
      let validacion = true
      if (this.preciosCantidad.codigoProducto !== null) {
        const codigoExcel = this.productosSinError
          .filter(pr => pr.codigo === this.preciosCantidad.codigoProducto).length
        validacion = codigoExcel === 1
      }
      return validacion
    },
    validaBodyExcelPreciosCantidad(filaData, keyDataFila) {
      filaData.forEach((colData, keyColData) => {
        const celda = `Celda ${this.columnaPreciosCantidad[keyColData]}${keyDataFila + 1}`
        const nombreCol = `${this.nombreColumnaPreciosCantidad[keyColData]}`
        const msgRutaLog = `${celda}: ${nombreCol} ->`
        const msgRequerido = 'es requerido'
        let msg = ''

        switch (keyColData) {
          case 0:
            this.preciosCantidad.codigoProducto = colData
            if (!this.$v.preciosCantidad.codigoProducto.required) {
              msg = `${msgRutaLog} ${msgRequerido}`
            }

            if (!this.$v.preciosCantidad.codigoProducto.existeProducto) {
              msg = `${msgRutaLog} no se encontró en el listado de productos cargados`
            }
            break
          case 1:
            this.preciosCantidad.cantidad = colData
            if (!this.$v.preciosCantidad.cantidad.required) {
              msg = `${msgRutaLog} ${msgRequerido}`
            }
            break
          case 2:
            this.preciosCantidad.precio = colData
            if (!this.$v.preciosCantidad.precio.required) {
              msg = `${msgRutaLog} ${msgRequerido}`
            }
            break

          default:
            break
        }
        this.$v.preciosCantidad.$touch()
        if (msg.length) {
          this.dataErrorMsgPreciosCantidad.push(msg)
        }
      })
    },
    validaBodyExcelProductos(filaData, keyDataFila) {
      filaData.forEach((colData, keyColData) => {
        const celda = `Celda ${this.columna[keyColData]}${keyDataFila + 1}`
        const nombreCol = `${this.nombreColumna[keyColData]}`
        const msgRutaLog = `${celda}: ${nombreCol} ->`
        const msgRequerido = 'es requerido'
        let msg = ''
        let txtUnidades
        switch (keyColData) {
          case 0:
            this.productos.nombre = colData
            if (!this.$v.productos.nombre.required) {
              msg = `${msgRutaLog} ${msgRequerido}`
            }
            if (!this.$v.productos.nombre.maxLength) {
              msg = `${msgRutaLog} debe tener máximo 80 caracteres`
            }
            break
          case 1:

            this.productos.codigo = colData
            if (!this.$v.productos.codigo.required) {
              msg = `${msgRutaLog} ${msgRequerido}`
            }
            if (!this.$v.productos.codigo.maxLength) {
              msg = `${msgRutaLog} debe tener máximo 30 caracteres`
            }

            if (!this.$v.productos.codigo.duplicadoExcel) {
              msg = `${msgRutaLog} no se puede repetir en el excel`
            }

            if (!this.$v.productos.codigo.duplicadoSys) {
              msg = `${msgRutaLog} ya existe en el sistema`
            }

            break
          case 2:
            this.productos.unidad = colData.toUpperCase()
            if (!this.$v.productos.unidad.existeUnidad) {
              this.unidades.forEach((unidad, key) => {
                if (key === 0) {
                  txtUnidades = `${unidad.value}`
                } else {
                  txtUnidades += ` > ${unidad.value}`
                }
              })
              msg = `${msgRutaLog} no es una unidad válida (${txtUnidades})`
            }
            break
          case 3:
            this.productos.categoria = colData
            break
          case 4:
            this.productos.ubicacion = colData
            break
          case 5:
            this.productos.stock = colData
            break
          case 6:
            this.productos.tramoDesdeRojo = colData
            if (!this.$v.productos.tramoDesdeRojo.required) {
              msg = `${msgRutaLog} ${msgRequerido}`
            }
            break
          case 7:
            this.productos.tramoDesdeAmarillo = colData
            if (!this.$v.productos.tramoDesdeAmarillo.mayorRojo) {
              msg = `${msgRutaLog} debe ser mayor a Alarma Roja`
            }
            if (!this.$v.productos.tramoDesdeAmarillo.required) {
              msg = `${msgRutaLog} ${msgRequerido}`
            }

            break
          case 8:
            this.productos.tramoDesdeVerde = colData
            if (!this.$v.productos.tramoDesdeVerde.mayorAmarillo) {
              msg = `${msgRutaLog} debe ser mayor a Alarma Amarilla`
            }
            if (!this.$v.productos.tramoDesdeVerde.required) {
              msg = `${msgRutaLog} ${msgRequerido}`
            }
            break
          case 9:
            this.productos.descripcion = colData
            break

          default:
            break
        }
        this.$v.productos.$touch()
        if (msg.length) {
          this.dataErrorMsg.push(msg)
        }
      })
    },
    validaHeaderExcel(header, formato, tipo) {
      let alert = false
      let alertHeader
      let alertCelda
      let errorCantidadCol
      let errorFaltaEncabezado
      const errorMsg = []
      if (formato.length !== header.length) {
        errorCantidadCol = `${tipo} -> CANTIDAD DE COLUMNAS -> Se han ingresado
          ${header.length} columnas, de ${formato.length}
          solicitadas en el formato.`
        errorMsg.push(errorCantidadCol)
        alert = true
      }

      formato.forEach((col, key) => {
        if (header[key] !== col) {
          alertHeader = col
          alertCelda = `${this.columna[key]}`
          alert = true
        }
      })

      if (alertHeader && alertCelda) {
        errorFaltaEncabezado = `${tipo} -> FALTA TÍTULO DE ENCABEZADO -> El
        título "${alertHeader}", no se encuentra en la celda "${alertCelda}"
        del documento seleccionado. Corrija el documento y vuelva a intentarlo.`
        errorMsg.push(errorFaltaEncabezado)
      }
      return alert ? errorMsg : ''
    },

    setCodigosExcel(productos) {
      productos.forEach((codigo, keyCodigo) => {
        if (keyCodigo !== 0 && codigo[1] !== null) {
          this.productoCodigos.push({ codigo: codigo[1] })
        }
      })
    },
    filterCodigoSys() {
      let validacion = true
      if (this.productos.codigo !== null) {
        const codigoExcel = this.getProductos
          .filter(gp => gp.codigo === this.productos.codigo).length
        validacion = codigoExcel === 0
      }
      return validacion
    },
    filterCodigoExcel() {
      let validacion = true
      if (this.productos.codigo !== null) {
        const codigoExcel = this.productoCodigos
          .filter(pc => pc.codigo === this.productos.codigo).length
        validacion = codigoExcel === 1
      }
      return validacion
    },
    filterUnidades() {
      let validacion = true

      if (this.productos.unidad !== null) {
        const filterUnidad = this.unidades
          .filter(u => u.value === this.productos.unidad).length
        validacion = filterUnidad === 1
      }
      return validacion
    },
    validaAmarillo() {
      return this.productos.tramoDesdeRojo < this.productos.tramoDesdeAmarillo
    },
    validaVerde() {
      return this.productos.tramoDesdeAmarillo < this.productos.tramoDesdeVerde
    },
    downloadFormat() {
      const urlModulo = this.modulo.toLowerCase()
      const url = `${axios.defaults.baseURL}/${urlModulo}/exportFormato`
      window.location.href = url
    },
    exportarErrores() {
      const findDescargado = this.descargados.filter(doc => doc !== 'exportarErrores')
      this.descargados = findDescargado
      if (this.dataProductos[0]) {
        // this.dataProductos[0].unshift(this.nombreColumna)
        const data = XLSX.utils.json_to_sheet(this.dataProductos[0], { skipHeader: true })
        const workbook = XLSX.utils.book_new()
        const filename = `errorData${this.modulo}`
        XLSX.utils.book_append_sheet(workbook, data, filename)
        XLSX.writeFile(workbook, `${filename}.xlsx`)
        this.descargados.push('exportarErrores')
      }
    },
    exportarLog() {
      const findDescargado = this.descargados.filter(doc => doc !== 'exportarLog')
      this.descargados = findDescargado
      if (this.dataErrorMsg) {
        const urlModulo = this.modulo.toLowerCase()
        const urlLog = `${axios.defaults.baseURL}/${urlModulo}/errors/logs/${this.dataErrorMsg}`
        window.location.href = urlLog
        this.descargados.push('exportarLog')
      }
    },
    downloadFormatPreciosCantidad() {
      const urlModulo = this.modulo.toLowerCase()
      const slug = 'exportFormatoPreciosCantidad'
      const url = `${axios.defaults.baseURL}/${urlModulo}/${slug}`
      window.location.href = url
    },
    exportarErroresPreciosCantidad() {
      const findDescargado = this.descargadosPreciosCantidad.filter(doc => doc !== 'exportarErrores')
      this.descargadosPreciosCantidad = findDescargado
      if (this.dataPreciosCantidad[0]) {
        // this.dataPreciosCantidad[0].unshift(this.nombreColumnaPreciosCantidad)
        const data = XLSX.utils.json_to_sheet(this.dataPreciosCantidad[0], { skipHeader: true })
        const workbook = XLSX.utils.book_new()
        const filename = `errorData${this.moduloPreciosCantidad}`
        XLSX.utils.book_append_sheet(workbook, data, filename)
        XLSX.writeFile(workbook, `${filename}.xlsx`)
        this.descargadosPreciosCantidad.push('exportarErrores')
      }
    },
    exportarLogPreciosCantidad() {
      const findDescargado = this.descargadosPreciosCantidad.filter(doc => doc !== 'exportarLog')
      this.descargadosPreciosCantidad = findDescargado
      if (this.dataErrorMsgPreciosCantidad) {
        const urlModulo = this.moduloPreciosCantidad.toLowerCase()
        const urlLog = `${axios.defaults.baseURL}/${urlModulo}/errors/logs/${this.dataErrorMsgPreciosCantidad}`
        window.location.href = urlLog
        this.descargadosPreciosCantidad.push('exportarLog')
      }
    },

    processImport() {
      this.cargando = true
      const data = {
        productos: this.dataExcel,
        preciosCantidad: this.dataExcelPreciosCantidad,
      }
      this.importProductos(data)
        .then(() => {
          this.fetchProductos().then(() => {
            this.closeModal()
            this.cargando = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Productos creados con éxito 👍',
                icon: 'CheckIcon',
                text: 'El excel de productos fue importado con éxito!',
                variant: 'success',
              },
            },
            {
              timeout: 4000,
            })
          })
        })
    },
    resetProductos() {
      this.fileProductos = null
      this.fileNameProductos = ''
      this.dataProductos = []
      this.dataExcel = []
      this.dataError = []
      this.dataErrorMsg = []
      this.headerErrorMsg = []
      this.descargados = []
      this.productoCodigos = []
      this.productosSinError = []
    },
    resetPreciosCantidad() {
      this.filePreciosCantidad = null
      this.fileNamePreciosCantidad = ''
      this.dataPreciosCantidad = []
      this.dataExcelPreciosCantidad = []
      this.dataErrorPreciosCantidad = []
      this.dataErrorMsgPreciosCantidad = []
      this.headerErrorMsgPreciosCantidad = []
      this.descargadosPreciosCantidad = []
    },
    closeModal() {
      this.resetProductos()
      this.resetPreciosCantidad()
      this.$root.$emit('bv::hide::modal', `modal-importar-${this.modulo}`)
    },
  },
}
</script>

<style>

</style>
